/**
 * Module dependencies
 */
const React = require('react');
const { hydrate } = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { AndesProvider } = require('@andes/context');
const newRelicErrorHandler = require('../utils/services/newRelic/errorHandler');

function startApp(Component) {
  /**
   * Get server state
   */
  const {
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    translations,
    shopModel,
    config,
    editable,
    section,
    siteKey,
    apiBasePath,
    showModalSubscription,
    isHubPreview,
    manifest,
    assetsPrefix,
    uuidVersion,
    locale,
    device,
  } = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

  /**
   * i18n
   */
  const i18n = new I18n({ translations });

  if (window && window.newrelic) {
    window.newrelic.setErrorHandler(newRelicErrorHandler);
  }

  /**
   * Mount HomeView on client
   */
  hydrate(
    <AndesProvider locale={locale} device={device}>
      <I18nProvider i18n={i18n}>
        <Component
          site={site}
          siteId={siteId}
          lowEnd={lowEnd}
          deviceType={deviceType}
          company={company}
          shopModel={shopModel}
          config={config}
          editable={editable}
          section={section}
          siteKey={siteKey}
          apiBasePath={apiBasePath}
          showModalSubscription={showModalSubscription}
          isHubPreview={isHubPreview}
          manifest={manifest}
          assetsPrefix={assetsPrefix}
          uuidVersion={uuidVersion}
          locale={locale}
          device={device}
        />
      </I18nProvider>
    </AndesProvider>,
    document.getElementById('root-app'),
  );
}

module.exports = startApp;
