const LAYOUT_ACTIONS = {
  UPDATE_RAW_HTML: 'UPDATE_RAW_HTML',
  CHANGE_EDITABLE_MODE: 'CHANGE_EDITABLE_MODE',
  LAYOUT_ORDER: 'LAYOUT_ORDER',
  RESET_LAYOUT: 'RESET_LAYOUT',
  SAVE_LAYOUT: 'SAVE_LAYOUT',
  LAYOUT_HIDDEN_COMPONENT: 'LAYOUT_HIDDEN_COMPONENT',
  LAYOUT_REMOVE_COMPONENT: 'LAYOUT_REMOVE_COMPONENT',
  UPDATE_FROM_SERVER: 'UPDATE_FROM_SERVER',
  UPDATE_LAYOUT: 'UPDATE_LAYOUT',
  CHANGE_DEVICE: 'CLIENT_CHANGE_DEVICE',
  CHANGE_OSNAME: 'CLIENT_CHANGE_OSNAME',
  ADD_COMPONENT: 'ADD_COMPONENT',
  UPDATE_COMPONENT_PROPERTIES: 'UPDATE_COMPONENT_PROPERTIES',
  UPDATE_LAYOUT_PROPERTIES: 'UPDATE_LAYOUT_PROPERTIES',
  SECTION_EDITING: 'SECTION_EDITING',
  UPDATE_COMPONENT_NAME: 'UPDATE_COMPONENT_NAME',
  LAYOUT_UPDATE_MODERATION: 'LAYOUT_UPDATE_MODERATION',
  SCROLL_FROM_EDITOR: 'SCROLL_FROM_EDITOR',
};

const LAYOUT_UPDATE_MODES = {
  FULL: 'FULL', // Full update layout structure
  PARTIAL: 'PARTIAL', // Update only given component props
};

module.exports = { LAYOUT_ACTIONS, LAYOUT_UPDATE_MODES };
